port module Main exposing (init, main, subscriptions)

import Browser
import Browser.Navigation as Nav
import Html
    exposing
        ( Html
        , a
        , article
        , br
        , div
        , em
        , footer
        , h1
        , header
        , li
        , nav
        , p
        , pre
        , span
        , text
        , ul
        )
import Html.Attributes exposing (class, href, id)
import Html.Events exposing (onClick)
import Json.Encode as Encode
import Random
import Random.List exposing (shuffle)
import Task
import Time
import TimeZone exposing (america__los_angeles, america__new_york)
import Url
import Url.Parser as UrlParser exposing (Parser, map, oneOf, s, top)
import Utils.Clock as Clock exposing (creamColors)
import Utils.RomanNumeral exposing (arabicToRoman)



-- PORTS


{-| Fired when our application has been initialized so we can do other things
in JavaScript
-}
port ready : Encode.Value -> Cmd msg



-- MSGS


type Msg
    = LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url
    | OnGetCurrentYear Int
    | Tick Time.Posix
    | ToggleRomanNumerals
    | GeneratedLosAngelesClockColors (List String)
    | GeneratedNewYorkClockColors (List String)
    | GeneratedInternetClockColors (List String)



-- MODELS


type Route
    = HomeRoute
    | InfoRoute
    | NotFoundRoute


type alias Flags =
    { cool : Bool
    }


type alias Model =
    { flags : Flags
    , key : Nav.Key
    , url : Url.Url
    , route : Route
    , now : Time.Posix
    , year : Maybe Int
    , useRomanNumerals : Bool
    , americaLosAngeles : Time.Zone
    , americaNewYork : Time.Zone
    , losAngelesClockColors : List String
    , newYorkClockColors : List String
    , internetClockColors : List String
    }



-- ROUTING


routes : Parser (Route -> a) a
routes =
    oneOf
        [ map HomeRoute top
        , map InfoRoute (s "info")
        ]


urlToRoute : Url.Url -> Route
urlToRoute url =
    case UrlParser.parse routes url of
        Just route ->
            route

        Nothing ->
            NotFoundRoute



-- COMMANDS


getCurrentYear : Cmd Msg
getCurrentYear =
    Task.perform OnGetCurrentYear whatYearIsIt


whatYearIsIt : Task.Task x Int
whatYearIsIt =
    Task.map2 Time.toYear Time.here Time.now


{-| Usage: `generateClockColors GeneratedNewYorkClockColors`
-}
generateClockColors : (List String -> Msg) -> Cmd Msg
generateClockColors msg =
    Random.generate msg (shuffle creamColors)



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.key (Url.toString url) )

                Browser.External href ->
                    ( model, Nav.load href )

        UrlChanged url ->
            ( { model
                | url = url
                , route = urlToRoute url
              }
            , Cmd.none
            )

        OnGetCurrentYear int ->
            ( { model | year = Just int }
            , ready (Encode.bool True)
            )

        Tick time ->
            ( { model | now = time }, Cmd.none )

        ToggleRomanNumerals ->
            ( { model | useRomanNumerals = not model.useRomanNumerals }
            , Cmd.none
            )

        GeneratedLosAngelesClockColors colors ->
            ( { model | losAngelesClockColors = colors }
            , Cmd.none
            )

        GeneratedNewYorkClockColors colors ->
            ( { model | newYorkClockColors = colors }
            , Cmd.none
            )

        GeneratedInternetClockColors colors ->
            ( { model | internetClockColors = colors }
            , Cmd.none
            )



-- VIEWS


view : Model -> Browser.Document Msg
view model =
    let
        title =
            case model.route of
                NotFoundRoute ->
                    "404"

                _ ->
                    "Christian Rocha"
    in
    { title = title
    , body = [ body model ]
    }


body : Model -> Html Msg
body model =
    let
        childView =
            case model.route of
                HomeRoute ->
                    [ homeView model ]

                InfoRoute ->
                    infoView model

                NotFoundRoute ->
                    [ notFoundView model ]

        idName =
            case model.route of
                InfoRoute ->
                    "about"

                NotFoundRoute ->
                    "error"

                _ ->
                    ""
    in
    div [ class "page", id idName ]
        [ headerView model
        , div [ class "content" ] childView
        , footerView model
        ]


headerView : Model -> Html Msg
headerView model =
    let
        infoLinkUrl =
            case model.route of
                InfoRoute ->
                    "/"

                _ ->
                    "/info"
    in
    header []
        [ nav []
            [ ul []
                [ li [] [ a [ href infoLinkUrl ] [ text "Info" ] ]
                ]
            ]
        , h1 [] [ a [ href "/" ] [ text "Christian Rocha" ] ]
        , p [] [ text "Code & Form" ]
        ]


footerView : Model -> Html Msg
footerView model =
    footer []
        [ p [ onClick ToggleRomanNumerals ]
            [ text <| yearAsString model ++ "." ]
        ]


notFoundView : Model -> Html Msg
notFoundView _ =
    div []
        [ h1 [] [ text "404 Not Found" ]
        , p [] [ text "The requested resource was not found at this location." ]
        , p [] [ a [ href "/" ] [ text "Let’s go home." ] ]
        ]


homeView : Model -> Html Msg
homeView _ =
    div [ class "hidden" ]
        [ em [] []
        , pre [] []
        ]


infoView : Model -> List (Html Msg)
infoView model =
    [ article []
        [ p []
            [ text "Christian Rocha is a self-proclaimed creative "
            , em [] [ text "tour de force" ]
            , text " who blurs the lines between art, design and technology. He believes the creative and technical disciplines go hand-in-hand by the nature of the practice."
            ]
        , p []
            [ text "Christian works on "
            , a [ href "https://charm.sh/" ] [ text "Charm" ]
            , text ", where "
            , a [ href "https://twitter.com/processtype/status/1453028262884564992" ] [ text "fashion and the command line collide!" ]
            ]
        , p [] [ text "Christian was born in Los Angeles and lives and works in New York, and on the internet." ]
        ]
    , ul [ class "clocks" ]
        [ li []
            [ Clock.view model.americaLosAngeles model.now model.losAngelesClockColors
            , span [] [ text "Los Angeles" ]
            ]
        , li []
            [ Clock.view model.americaNewYork model.now model.newYorkClockColors
            , span [] [ text "New York" ]
            ]
        , li []
            [ Clock.view Time.utc model.now model.internetClockColors
            , span [] [ text "Internet" ]
            ]
        ]
    , div [ class "contact" ]
        [ p [] [ a [ href "mailto:christian@rocha.is" ] [ text "christian@rocha.is" ] ]
        , p [ href "tel:+12122039213" ] [ text "+1 (212) 203-9213" ]
        , p []
            [ text "Christian Rocha"
            , br [] []
            , text "c/o Charmbracelet, Inc."
            , br [] []
            , text "49-51 Elizabeth Street"
            , br [] []
            , text "4th Floor"
            , br [] []
            , text "New York, NY 10013"
            , br [] []
            , text "United States"
            ]
        , p [ class "gpg" ]
            [ span [] []
            , a [ href "https://gist.githubusercontent.com/meowgorithm/16674fbd19c5ba2e1daf5ebf75b8c60f/raw/758198a449115b2dbc3541425abd394021a6ba53/D6CC7A16E5878018.asc" ] [ text "6AC9 B8D9 1B86 35AB 0752  FD04 C630 6464 7412 088C" ]
            ]
        , ul []
            [ li [] [ a [ href "https://twitter.com/meowgorithm" ] [ text "Twitter" ] ]
            , li [] [ a [ href "https://instagram.com/maison_rocha" ] [ text "Instagram" ] ]
            , li [] [ a [ href "https://github.com/meowgorithm" ] [ text "GitHub" ] ]
            ]
        ]
    , p [ class "copyright" ]
        [ text "Works © "
        , span [ onClick ToggleRomanNumerals ] [ text (yearAsString model) ]
        , text " Christian Rocha unless otherwise noted. Please do not reproduce without the expressed written consent of the author."
        ]
    ]


yearAsString : Model -> String
yearAsString model =
    case model.year of
        Nothing ->
            ""

        Just year ->
            if model.useRomanNumerals then
                case arabicToRoman year of
                    Nothing ->
                        ""

                    Just roman ->
                        roman

            else
                String.fromInt year



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
    Time.every 1000 Tick



-- INIT


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    ( { flags = flags
      , key = key
      , url = url
      , route = urlToRoute url
      , now = Time.millisToPosix 0
      , year = Nothing
      , useRomanNumerals = True
      , americaLosAngeles = america__los_angeles ()
      , americaNewYork = america__new_york ()
      , losAngelesClockColors = creamColors
      , newYorkClockColors = creamColors
      , internetClockColors = creamColors
      }
    , Cmd.batch
        [ getCurrentYear
        , generateClockColors GeneratedLosAngelesClockColors
        , generateClockColors GeneratedNewYorkClockColors
        , generateClockColors GeneratedInternetClockColors
        ]
    )


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        }
