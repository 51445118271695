// vim:et:sw=4

import * as THREE from "three";
import TWEEN from "tween.js";

let renderer,
    scene,
    camera,
    object,
    dpr,
    probablyMobile = screen.width < 768,
    lastWindowWidth,
    lastWindowHeight;

/**
 * Initialize the sclupture
 */
export default function init() {
    // Determine device pixel ratio so we can render properly on high density displays
    dpr = 1;
    if (window.devicePixelRatio !== undefined) {
        dpr = window.devicePixelRatio;
    }

    // Init renderer
    renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
    });
    renderer.setPixelRatio(dpr);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    document.body.appendChild(renderer.domElement);

    // Init camera
    camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        1,
    );
    camera.position.z = 400;

    // Init scene
    scene = new THREE.Scene();
    scene.fog = new THREE.Fog(0xfbc8bc, 1, 700);

    // Add sphere things
    object = new THREE.Object3D();
    scene.add(object);
    for (let i = 0; i < 100; i++) {
        new Sphere(object);
    }

    // Lights

    scene.add(new THREE.AmbientLight(0xdba295));

    let light = new THREE.DirectionalLight(0xffffff, 0.4);
    light.position.set(1, 1, 1);
    scene.add(light);

    // For working with window resizes
    lastWindowWidth = window.innerWidth;
    lastWindowHeight = window.innerWidth;

    if (probablyMobile) {
        sizeCanvas();
    }

    animate();
}

/**
 * Render loop
 */
function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);

    object.rotation.x += 0.0005;
    object.rotation.y += 0.001;

    renderer.render(scene, camera);
}

/**
 * Properly size the canvas to the window size
 */
function sizeCanvas() {
    let w, h;
    if (!probablyMobile) {
        w = window.innerWidth;
        h = window.innerHeight;
    } else {
        w = screen.width;
        h = screen.height;
    }

    camera.aspect = w / h;
    camera.updateProjectionMatrix();
    renderer.setSize(w, h);
}

/**
 * Listen for window resizes
 */
window.addEventListener(
    "resize",
    function(e) {
        // Abort if the window size change on mobile was not very large. This
        // keeps us from adjusting things when subtle changes like hiding and
        // showing browser chrome occurs, particularly on mobile devices
        if (probablyMobile) {
            let widthDelta = Math.abs(lastWindowWidth - window.innerWidth);
            let heightDelta = Math.abs(lastWindowHeight - window.innerHeight);

            lastWindowWidth = window.innerWidth;
            lastWindowHeight = window.innerHeight;

            if (widthDelta < 100 && heightDelta < 100) {
                return;
            }
        }

        sizeCanvas();
    },
    false,
);

/**
 * Represents a sphere-thing
 */
function Sphere(parentObj) {
    // NOTE: At the time of writing (2014) iOS seems to have trouble
    // rendering materials with transparency
    let material = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        flatShading: true,
        transparent: false,
        opacity: 1,
    });
    let geometry = new THREE.SphereGeometry(
        1,
        THREE.Math.randInt(1, 4),
        THREE.Math.randInt(2, 4),
    );
    let mesh = new THREE.Mesh(geometry, material);

    mesh.position
        .set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5)
        .normalize();
    mesh.position.multiplyScalar(Math.random() * 400);
    mesh.scale.x = mesh.scale.y = mesh.scale.z = 0.01;
    mesh.rotation.x = Math.random() * 360;

    parentObj.add(mesh);

    //if (probablyMobile) {
    // On mobile just show the mesh immediately
    //mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 50;
    //} else {

    // Animate in mesh
    // NOTE: Scale can't animate from at 0 or three.js gets annoyed, so as a
    // workaround we just start at a super low number
    // https://github.com/aframevr/aframe-inspector/issues/524#issuecomment-392551948
    new TWEEN.Tween({ scale: 0.001 })
        .to({ scale: Math.random() * 50 }, 1000)
        .easing(TWEEN.Easing.Elastic.Out)
        .onUpdate(function(time) {
            mesh.scale.set(this.scale, this.scale, this.scale);
        })
        .delay(THREE.Math.randInt(1000, 1500))
        .start();

    //}
}
