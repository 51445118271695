import "../css/main.scss";

import { Elm } from "../Main.elm";
import initSculpture from "./acne";

let app = Elm.Main.init({
    flags: {
        cool: true,
    },
});

// After Elm is ready, setup the sculpture
app.ports.ready.subscribe(data => {
    initSculpture();
});
